import * as React from "react"
import { graphql } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUserCircle } from "@fortawesome/free-solid-svg-icons"

import Layout from "../components/layout"
import Seo from "../components/seo"

const ProfilePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Profile" />
      <h1 className="sub-title"><FontAwesomeIcon icon={faUserCircle} /> Profile</h1>
      <h2>面白いことは自分でつくる</h2>
      <p><strong>Jack-s Blog</strong> は自分が面白いと感じたこと、気になることを記していくブログサイトです。</p>
      <p>技術ブログ的に様々なソフトウェアツールのノウハウを記録したり、ライフハック（＝生活を豊かにする生活術）や旅行など、
      興味に感じたことをアウトプットしていきます。</p>
      <p><strong>「人は遊んで生きても80年。
      世界を知っているつもりでも、本当に自分の目で見たものや自分の舌で味わったものは数えるほどしかない。」</strong></p>
      <p>新たに面白いことを知り、見たことのない景色を見たり美味しいものを味わえる、そんな豊かな毎日をつくっていきたいです。</p>
      <h2>このWebサイトについて</h2>
      <p>URL は <code>https://blog.jack-s.com</code> です。<br />
      Webmaster、著者は jack-s です。</p>
      <p>お問い合わせは、<code>webmaster@jack-s.com</code> まで。</p>
      <h2>プライバシーポリシー</h2>
      <p>このサイトでは個人を識別するような名前やパスワードなどの情報（個人情報）は取り扱いません。
        また閲覧履歴をデータとして保存するクッキー (Cookie) も使用しません。</p>
      <p>プライバシーポリシーは随時変更されます。重要な変更を行う場合はWebサイトに掲載しお知らせします。</p>
      <h2>プロフィール</h2>
      <h5>jack-s</h5>
      <p>不惑の元インフラエンジニア。ANA スーパーフライヤーズ (SFC) 会員。陸マイラー。
      マイルを貯めて国内、海外いろいろなところを旅してます。
      最近のお気に入りは、iPad mini 5 と Raspberry Pi。</p>
    </Layout>
  )
}

export default ProfilePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
